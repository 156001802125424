<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 19"
  >
    <path
      d="M16.84 1H5.16A4.156 4.156 0 0 0 1 5.16v8.338c0 2.31 1.867 4.16 4.16 4.16h11.68c2.309 0 4.16-1.867 4.16-4.16V5.161C21 2.85 19.133 1 16.84 1Z"
      stroke="#133949"
      stroke-miterlimit="10"
    />
    <path
      d="m14.283 8.224-4.8-2.408c-.622-.311-1.375-.066-1.67.557-.082.18-.13.36-.13.557v4.8a1.244 1.244 0 0 0 1.8 1.113l4.8-2.408a1.237 1.237 0 0 0 0-2.227v.016Z"
      stroke="#133949"
      stroke-miterlimit="10"
    />
  </svg>
</template>
