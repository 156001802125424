<script lang="ts" setup>
import type { NavigationItem } from "~/types/navigation";
import type { PageKey } from "~/types/page";

defineProps<{
  amerikadaVize: NavigationItem;
  greenCard: NavigationItem;
  kaynaklar: NavigationItem;
  hakkimizda: NavigationItem;
}>();

const { t } = useI18n();

const selected = ref<NavigationItem | null>(null);

// Search
const query = ref("");

const router = useRouter();

function handleSearch() {
  if (query.value.trim().length > 0) {
    router.push({ path: `${"search" satisfies PageKey}`, query: { q: query.value } });
  }
}
</script>

<template>
  <div
    v-auto-animate
    class="flex h-[calc(100vh-var(--header-height))] flex-col"
  >
    <!-- Search -->
    <div
      v-if="!selected"
      class="border-gray-200 flex items-center gap-3 border-b px-4 py-6 sm:px-6"
    >
      <IconSearch class="size-5" />
      <UInput
        v-model="query"
        size="xl"
        variant="none"
        :placeholder="t('searchButtonPlaceholder')"
        class="w-full"
        :padded="false"
        @keydown.enter="handleSearch"
        @keyup.enter="handleSearch"
      />
    </div>

    <div
      v-auto-animate
      class="flex-grow px-4 pb-6 pt-10 sm:px-6"
    >
      <!-- Navigation Items -->
      <div
        v-if="!selected"
        class="flex flex-col gap-6"
      >
        <HeaderSubtitle
          :title="amerikadaVize.navLabel as string"
          no-border
          @click.prevent="selected = amerikadaVize"
        />

        <HeaderSubtitle
          :title="greenCard.navLabel as string"
          no-border
          @click.prevent="selected = greenCard"
        />

        <HeaderSubtitle
          :title="kaynaklar.navLabel as string"
          no-border
          @click.prevent="selected = kaynaklar"
        />

        <HeaderSubtitle
          :title="hakkimizda.navLabel as string"
          no-border
          :to="hakkimizda.to"
          @click.prevent="selected = hakkimizda"
        />
      </div>

      <div v-if="selected && selected?.navLabel !== hakkimizda.navLabel ">
        <div class="flex items-baseline gap-4">
          <!-- Navigate Back -->
          <div
            class="flex items-center gap-4"
          >
            <UButton
              class="rounded-full p-2 shadow"
              color="gray"
              @click.prevent="selected = null"
            >
              <IconChevronLeft class="size-6" />
            </UButton>
          </div>

          <HeaderTitle>{{ selected?.description }}</HeaderTitle>
        </div>
        <div
          v-for="(s, idx) in selected?.subtitles"
          :key="idx"
          class="mt-8"
        >
          <HeaderSubtitle
            :title="s.title"
            :icon="s.icon"
            :to="s.to"
          />
          <div
            v-if="s.items"
            class="mb-8 mt-4 flex flex-col gap-4"
          >
            <HeaderLink
              v-for="(l, idx2) in s.items"
              :key="idx2"
              :label="l.title"
              :to="l.to"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <Footer minimize />
  </div>
</template>
