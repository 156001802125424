<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 73 40"
  >
    <path
      d="M72.8 0H.578v40H72.8V0Z"
      fill="#133949"
    />
    <path
      d="M31.958 18.395c.036.452.036 1.002.036 1.206 0 3.796-1.242 6.723-3.796 9.1a12.669 12.669 0 0 1-8.444 3.237c-6.829 0-12.035-5.135-12.035-11.894 0-6.758 5.17-12 11.964-12 3.858 0 7.344 1.659 9.685 4.55l-4.24 3.273c-1.48-1.827-3.103-2.652-5.276-2.652-3.69 0-6.484 2.927-6.484 6.865s2.963 6.794 6.723 6.794c2.652 0 4.825-1.517 5.756-3.965H20.19v-4.514h11.77ZM47.364 26.98v4.515H34.3V8.497h5.587V26.98h7.486-.009ZM55.816 17.712h7.654v4.514h-7.654v9.277h-5.588V8.506H63.47v4.514h-7.654v4.692Z"
      fill="#fff"
    />
    <path
      d="M63.46 31.503a3.024 3.024 0 1 0 0-6.048 3.024 3.024 0 0 0 0 6.048Z"
      fill="#D75660"
    />
  </svg>
</template>
