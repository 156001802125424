<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
  >
    <path
      d="M11.5 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM21.5 21l-4.35-4.35"
      stroke="#133949"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
