<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 20"
  >
    <path
      d="M4.098 6.974V4.49a1.854 1.854 0 1 1 3.71 0v2.483M5.952 2.636V0M4.71 17.517V20M7.191 17.517V20M1 19.986v-5.579a4.967 4.967 0 0 1 4.965-4.965 4.958 4.958 0 0 1 4.965 4.965v5.579"
      stroke="#102A35"
      stroke-miterlimit="10"
    />
    <path
      d="M1 15.648h2.483v-1.241a2.488 2.488 0 0 1 2.482-2.483 2.488 2.488 0 0 1 2.483 2.483v1.241h2.482M9.048 10.544V9.456A3.09 3.09 0 0 0 5.952 6.36a3.09 3.09 0 0 0-3.097 3.096v1.088"
      stroke="#102A35"
      stroke-miterlimit="10"
    />
  </svg>
</template>
