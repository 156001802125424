<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 19"
  >
    <path
      d="M.962 2.19c0-.505.187-.929.505-1.228.318-.3.795-.5 1.413-.5.615 0 1.075.2 1.382.499.307.299.493.725.507 1.234-.002.49-.183.913-.497 1.215-.315.302-.79.509-1.422.509h-.03c-.6 0-1.06-.199-1.368-.497-.307-.298-.49-.722-.49-1.232Z"
      fill="#133949"
      stroke="#133949"
      stroke-width=".923"
    />
    <path
      d="M4.96 6.1H.75v12.65h4.21V6.1ZM15.28 5.8c-2.27 0-3.79 2.13-3.79 2.13V6.1H7.28v12.65h4.21v-7.07c0-.38.03-.76.14-1.03.3-.76 1-1.54 2.16-1.54 1.52 0 2.13 1.16 2.13 2.86v6.77h4.21v-7.25c0-3.89-2.07-5.69-4.84-5.69h-.01Z"
      fill="#133949"
    />
  </svg>
</template>
