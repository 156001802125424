<template>
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.691 2.0004H8.31098C4.67098 2.0004 2.50098 4.1704 2.50098 7.8104V16.1804C2.50098 19.8304 4.67098 22.0004 8.31098 22.0004H16.681C20.321 22.0004 22.491 19.8304 22.491 16.1904V7.8104C22.501 4.1704 20.331 2.0004 16.691 2.0004ZM12.501 15.8804C10.361 15.8804 8.62098 14.1404 8.62098 12.0004C8.62098 9.8604 10.361 8.1204 12.501 8.1204C14.641 8.1204 16.381 9.8604 16.381 12.0004C16.381 14.1404 14.641 15.8804 12.501 15.8804ZM18.421 6.8804C18.371 7.0004 18.301 7.1104 18.211 7.2104C18.111 7.3004 18.001 7.3704 17.881 7.4204C17.761 7.4704 17.631 7.5004 17.501 7.5004C17.231 7.5004 16.981 7.4004 16.791 7.2104C16.701 7.1104 16.631 7.0004 16.581 6.8804C16.531 6.7604 16.501 6.6304 16.501 6.5004C16.501 6.3704 16.531 6.2404 16.581 6.1204C16.631 5.9904 16.701 5.8904 16.791 5.7904C17.021 5.5604 17.371 5.4504 17.691 5.5204C17.761 5.5304 17.821 5.5504 17.881 5.5804C17.941 5.6004 18.001 5.6304 18.061 5.6704C18.111 5.7004 18.161 5.7504 18.211 5.7904C18.301 5.8904 18.371 5.9904 18.421 6.1204C18.471 6.2404 18.501 6.3704 18.501 6.5004C18.501 6.6304 18.471 6.7604 18.421 6.8804Z"
      fill="#133949"
    />
  </svg>
</template>
