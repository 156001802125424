import * as Sentry from "@sentry/vue";
import type { Router } from "#vue-router";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  setup(nuxtApp) {
    const { sentryDsn } = useRuntimeConfig().public;
    const isDev = import.meta.dev;

    // If no sentry DSN set, ignore and warn in the console
    if (!sentryDsn) {
      console.warn("Sentry DSN not set, skipping Sentry initialization");
      return;
    }

    Sentry.init({
      enabled: !isDev,
      app: nuxtApp.vueApp,
      dsn: sentryDsn,
      environment: isDev ? "dev" : "prod",
      integrations: [
        Sentry.browserTracingIntegration({
          // We do 'as router' because we are using typed router of Nuxt
          router: useRouter() as Router,
        }),
        Sentry.replayIntegration(),
      ],
      logErrors: isDev,
      attachProps: true,
      autoSessionTracking: true,
      trackComponents: true,
      timeout: 3000,
      hooks: ["activate", "create", "destroy", "mount", "update"],

      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions

      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      beforeSend(event, hint) {
        if (isDev) {
          // Log to console in development
          if (event.exception)
            console.error(hint.originalException);

          // Return null to drop the event in development
          return null;
        }

        return event;
      },
    });

    return {
      provide: {
        sentry: {
          setUser: Sentry.setUser,
          captureError: Sentry.captureException,
        },
      },
    };
  },
});
