import type { PostHog } from "posthog-js";
import posthog from "posthog-js";

export default defineNuxtPlugin({
  name: "posthog",
  parallel: true,
  setup() {
    const isDev = import.meta.dev;
    if (isDev)
      return;

    const { posthogHost, posthogPublicKey } = useRuntimeConfig().public;

    const client = posthog.init(posthogPublicKey, {
      api_host: posthogHost,
      capture_pageview: false, // we add manual pageview capturing below
      loaded: (posthog) => {
        if (import.meta.dev)
          posthog.debug();
      },
    }) as PostHog | undefined;

    const router = useRouter();
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture("$pageview", {
          current_url: to.fullPath,
        });
      });
    });

    return {
      provide: {
        posthog: client,
      },
    };
  },
});
