<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M18.411 2H5.7a1.69 1.69 0 0 0-1.696 1.697v16.938a1.3 1.3 0 0 0 .66 1.21c.44.22.957.173 1.366-.11l5.924-3.834s.157-.047.236 0l5.924 3.834c.55.424 1.335.33 1.759-.22.188-.251.283-.565.252-.88V3.697A1.69 1.69 0 0 0 18.425 2h-.031.016Z"
      stroke="#102A35"
      stroke-miterlimit="10"
    />
    <path
      d="M7.837 8.976c0-2.435 3-3.315 4.038-.848.047.11.173.157.283.11a.19.19 0 0 0 .11-.11c1.037-2.467 4.037-1.587 4.037.848 0 2.012-3.393 4.683-4.1 5.201a.191.191 0 0 1-.252 0c-.723-.502-4.1-3.19-4.1-5.2h-.016Z"
      stroke="#102A35"
      stroke-miterlimit="10"
    />
  </svg>
</template>
