<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

defineProps<{
  label: string;
  disabled?: boolean;
}>();
</script>

<template>
  <Popover
    v-slot="{ open }"
    class="h-full"
  >
    <PopoverButton
      class="group inline-flex h-full shrink-0 items-center gap-x-2.5 px-1 hover:cursor-pointer focus:outline-none focus-visible:outline-0"
      :class="{
        'border-b-2 border-red-500': open,
        'border-b-2 border-transparent': !open,
      }"
      :disabled="disabled"
    >
      <HeaderNavText
        :label="label"
        class="group-hover:text-blue-700"
      />
      <IconChevronDown
        class="h-auto w-5 transition group-hover:text-blue-700"
        :class="{
          'rotate-180 transform': open,
        }"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="ring-gray-900/5 absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow ring-1"
      >
        <!-- Content -->
        <div class="border-gray-200 mt-10 border-t">
          <UContainer class="py-12">
            <slot :close="close" />
          </UContainer>
        </div>

        <!-- Overlay -->
        <div
          class="absolute inset-x-0 min-h-screen bg-text-title opacity-20"
          @click.prevent="close"
        />
      </PopoverPanel>
    </transition>
  </Popover>
</template>
