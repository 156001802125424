export default defineNuxtPlugin({
  name: "feedbucket",
  parallel: true,
  setup() {
    const { enableFeedback } = useRuntimeConfig().public;
    if (!enableFeedback || import.meta.dev) {
      return;
    }

    onNuxtReady(() => {
      useHead({
        script: [
          {
            type: "text/javascript",
            children: `
            (function(k,s) {
              s=document.createElement('script');s.module=true;s.defer=true;
              s.src="https://cdn.feedbucket.app/assets/feedbucket.js";
              s.dataset.feedbucket=k;document.head.appendChild(s);
            })('YiFAUxcuYlmQMK7R9DVX')
          `,
          },
        ],
      });
    });
  },
});
