import type { DirectiveBinding } from "vue";

export default defineNuxtPlugin({
  name: "external-link",
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive("external-link", {
      mounted(el: HTMLElement, _: DirectiveBinding) {
        const links = el.querySelectorAll<HTMLAnchorElement>("a");

        links.forEach((link) => {
          const href = link.getAttribute("href");
          const currentDomain = window.location.hostname;

          if (href && !href.includes(currentDomain)) {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
          }
        });
      },
    });
  },
});
