export default defineAppConfig({
  ui: {
    primary: "blue",
    gray: "snow",
    variables: {
      header: {
        height: "6rem",
      },
    },

    button: {
      padding: {
        xl: "px-6 py-3",
      },
      color: {
        white: {
          outline: "border-blue-950 border active:bg-blue-950 hover:bg-blue-950 focus:ring-0 focus:border-none focus:shadow-neumorphic shadow-none text-text-title hover:text-white focus:bg-blue-950 focus:text-white",
        },
      },

    },

    card: {
      rounded: "rounded-xl",
      body: {
        padding: "sm:p-8",
      },
    },

    container: {
      constrained: "max-w-[90rem]",
    },

    dashboard: {
      modal: {
        rounded: "sm:rounded-xl",
      },
    },

    divider: {
      border: {
        base: "border-text-title/50",
      },
    },

    input: {
      rounded: "rounded-[4px]",
      color: {
        gray: {
          outline: "focus:ring-1 focus:ring-blue-950 focus:shadow-neumorphic shadow-none",
        },
        white: {
          outline: "focus:ring-1 focus:ring-blue-950 focus:shadow-neumorphic shadow-none text-text-title",
        },
      },
    },

    landing: {
      section: {
        wrapper: "py-8 sm:py-16",
      },
    },

    page: {
      body: {
        prose: "prose prose-primary max-w-none prose-li:my-0 [&_th]:bg-gray-100 [&_td]:border-gray-200 [&_table]:my-4 [&_table]:w-full [&_table]:border-collapse [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2 [&_th]:font-semibold",
      },
    },

    select: {
      color: {
        gray: {
          outline: "focus:ring-1 focus:ring-blue-950 focus:shadow-neumorphic shadow-none",
        },
        white: {
          outline: "focus:ring-1 focus:ring-blue-950 focus:shadow-neumorphic shadow-none text-text-title",
        },
      },
    },

    selectMenu: {
      width: "w-full min-w-fit",
    },

    formGroup: {
      label: {
        base: "text-xs font-semibold tracking-tight text-gray-600",
      },
    },

    modal: {
      rounded: "rounded-xl",
    },

    textarea: {
      color: {
        gray: {
          outline: "focus:ring-1 focus:ring-blue-950 focus:shadow-neumorphic shadow-none",
        },
        white: {
          outline: "focus:ring-1 focus:ring-blue-950 focus:shadow-neumorphic shadow-none",
        },
      },
    },
  },
});
