<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path
      d="M1 11c0 5.524 4.476 10 10 10 5.523 0 10-4.476 10-10S16.522 1 11 1C5.476 1 1 5.476 1 11Z"
      stroke="#133949"
      stroke-miterlimit="10"
    />
    <path
      d="M8.651 14.222c0 .81.651 1.46 1.46 1.46a1.4 1.4 0 0 0 .953-.365l4.92-4.301-4.92-4.318a1.455 1.455 0 0 0-2.048.143 1.43 1.43 0 0 0-.365.953v6.428Z"
      stroke="#133949"
      stroke-miterlimit="10"
    />
  </svg>
</template>
