<script lang="ts" setup>
const enabled = defineModel<boolean>({
  default: false,
});

const { t } = useI18n();
const { getLocalePath } = usePath();

const showInput = ref(false);
const query = ref("");

const element = ref<HTMLElement>();
onClickOutside(element, () => disableSearch());

function enableSearch() {
  if (enabled.value)
    return;

  enabled.value = true;
  setTimeout(() => showInput.value = true, 250);
}

function disableSearch() {
  if (!enabled.value)
    return;

  showInput.value = false;
  setTimeout(() => enabled.value = false, 250);
  query.value = "";
}

async function handleSearch() {
  if (!enabled.value)
    return;

  if (query.value.trim().length > 0) {
    await navigateTo({ path: `${getLocalePath("search")}`, query: { q: query.value } });
    disableSearch();
  }
}

async function handleSearchIconClick() {
  if (!query.value)
    disableSearch();

  await handleSearch();
}
</script>

<template>
  <UButton
    ref="element"
    size="xl"
    variant="outline"
    color="gray"
    :aria-label="t('searchButtonLabel')"
    class="rounded-full p-3"
    @click.prevent="enableSearch"
  >
    <div
      v-auto-animate
      class="flex items-center gap-2"
    >
      <UInput
        v-if="showInput"
        v-model="query"
        variant="none"
        size="xl"
        class="w-64 px-2 xl:w-96"
        :placeholder="t('searchButtonPlaceholder')"
        :padded="false"
        :autofocus="true"
        @keydown.enter="handleSearch"
        @keyup.enter="handleSearch"
      />
      <IconSearch
        class="size-6 shrink-0"
        @click.prevent="handleSearchIconClick"
      />
    </div>
  </UButton>
</template>
