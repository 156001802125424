<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path
      d="M4.782 4.556A1.79 1.79 0 0 1 2.996 6.34a1.78 1.78 0 0 1-1.785-1.785C1.21 3.566 2.996 1 2.996 1s1.786 2.58 1.786 3.556ZM19.004 21h-4.006l1.08-4.321c.15-.6.676-1.005 1.29-1.005h1.921c.735 0 1.335.6 1.335 1.335 0 .105 0 .225-.045.33l-.255 1.005M6.342 13.003l-1.86-4.666M1.66 8.337l3.195 7.982M8.606 15.269l-2.28-2.28-2.236 5.025a1.33 1.33 0 0 0 .42 1.606l1.815 1.365M14.353 15.028 10.617 21M14.37 7.946c.554.18.914.69.914 1.276v3.78c0 1.47-1.2 2.671-2.67 2.671s-2.67-1.2-2.67-2.67V9.221c0-.57.374-1.08.914-1.26 1.14-.39 2.371-.39 3.496 0l.015-.016Z"
      stroke="#102A35"
      stroke-miterlimit="10"
    />
    <path
      d="M9.943 10.992a5.703 5.703 0 0 1 5.341 0M12.613 4.991v2.67M9.281 6.161l1.47 1.83M7.661 8.337l2.28.915M15.944 6.161l-1.47 1.83M17.562 8.337l-2.265.915M18.78 18.33H21V21h-2.22a.451.451 0 0 1-.45-.45v-1.786c0-.24.195-.45.45-.45v.015ZM1 8.337h4.666"
      stroke="#102A35"
      stroke-miterlimit="10"
    />
  </svg>
</template>
