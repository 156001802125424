import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetBlogCategoryIdBySlug","GetBlogDetails","GetBlogPosts","GetBlogCategories","GetMostViewedBlogPosts","GetPageSeo","GetHeaderServiceCategories","GetFooterNavigationLinks","GetFooterInformation","GetWorkingWithGrape","GetHomeInformations","GetAboutPageInformation","GetContactPageInformation","GetPageContent","Search","GetContactFormServicesOptions","GetTestimonials","GetTestimonialDetails","GetVideos","GetTopLevelVisaCategory","CheckVisaCategoryHasChildren","GetSecondLevelVisaCategory","GetSecondLevelVisaCategories","GetVisaDetails","GetWebinars"]}
export const GqlCheckVisaCategoryHasChildren = (...params) => useGql()('CheckVisaCategoryHasChildren', ...params)
export const GqlGetAboutPageInformation = (...params) => useGql()('GetAboutPageInformation', ...params)
export const GqlGetBlogCategories = (...params) => useGql()('GetBlogCategories', ...params)
export const GqlGetBlogCategoryIdBySlug = (...params) => useGql()('GetBlogCategoryIdBySlug', ...params)
export const GqlGetBlogDetails = (...params) => useGql()('GetBlogDetails', ...params)
export const GqlGetBlogPosts = (...params) => useGql()('GetBlogPosts', ...params)
export const GqlGetContactFormServicesOptions = (...params) => useGql()('GetContactFormServicesOptions', ...params)
export const GqlGetContactPageInformation = (...params) => useGql()('GetContactPageInformation', ...params)
export const GqlGetFooterInformation = (...params) => useGql()('GetFooterInformation', ...params)
export const GqlGetFooterNavigationLinks = (...params) => useGql()('GetFooterNavigationLinks', ...params)
export const GqlGetHeaderServiceCategories = (...params) => useGql()('GetHeaderServiceCategories', ...params)
export const GqlGetHomeInformations = (...params) => useGql()('GetHomeInformations', ...params)
export const GqlGetMostViewedBlogPosts = (...params) => useGql()('GetMostViewedBlogPosts', ...params)
export const GqlGetPageContent = (...params) => useGql()('GetPageContent', ...params)
export const GqlGetPageSeo = (...params) => useGql()('GetPageSeo', ...params)
export const GqlGetSecondLevelVisaCategories = (...params) => useGql()('GetSecondLevelVisaCategories', ...params)
export const GqlGetSecondLevelVisaCategory = (...params) => useGql()('GetSecondLevelVisaCategory', ...params)
export const GqlGetTestimonialDetails = (...params) => useGql()('GetTestimonialDetails', ...params)
export const GqlGetTestimonials = (...params) => useGql()('GetTestimonials', ...params)
export const GqlGetTopLevelVisaCategory = (...params) => useGql()('GetTopLevelVisaCategory', ...params)
export const GqlGetVideos = (...params) => useGql()('GetVideos', ...params)
export const GqlGetVisaDetails = (...params) => useGql()('GetVisaDetails', ...params)
export const GqlGetWebinars = (...params) => useGql()('GetWebinars', ...params)
export const GqlGetWorkingWithGrape = (...params) => useGql()('GetWorkingWithGrape', ...params)
export const GqlSearch = (...params) => useGql()('Search', ...params)