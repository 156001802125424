const _transparent = "transparent"
const _current = "currentColor"
const _black = "#000000"
const _white = "#ffffff"
const _overlay = {"green":"#8A9600","blue":"#006A5D"}
const _testimonial = {"greenCard":"#E0FFB8","work":"#ffedd5","investor":"#f6fec9","trade":"#ffe1e2"}
const _snow = {"50":"#f7f8f9","100":"#edeff1","200":"#d7dce0","300":"#b3bec6","400":"#8a9aa6","500":"#6c7e8b","600":"#566673","700":"#47535d","800":"#3d474f","900":"#363e44","950":"#24282d"}
const _blue = {"50":"#eefcfd","100":"#d5f5f8","200":"#b0e9f1","300":"#7ad9e6","400":"#3cbed4","500":"#20a1ba","600":"#1e829c","700":"#1f6a7f","800":"#215769","900":"#204959","950":"#133949"}
const _red = {"50":"#fef2f2","100":"#fde6e6","200":"#fbd0d3","300":"#f8a9af","400":"#f37985","500":"#e9495d","600":"#d52947","700":"#b41c3a","800":"#971a37","900":"#811a35","950":"#480918"}
const _green = {"50":"#eefffb","100":"#c6fff3","200":"#8effe9","300":"#4dfbdd","400":"#19e8cb","500":"#00c0a8","600":"#00a494","700":"#028377","800":"#086760","900":"#0c554f","950":"#003432"}
const _pink = {"50":"#fef2f3","100":"#ffe1e2","200":"#ffbfc2","300":"#fea3a7","400":"#fb6e75","500":"#f24149","600":"#e0222b","700":"#bc1921","800":"#9b191f","900":"#811b20","950":"#46090c"}
const _yellow = {"50":"#fbffe6","100":"#f6fec9","200":"#e9fc92","300":"#d9f75f","400":"#c5ec2f","500":"#a6d210","600":"#81a808","700":"#62800b","800":"#4d650f","900":"#415512","950":"#212f04"}
const _text = {"title":"#133949","primary":"#204959","secondary":"#215769","paragraph":"#102A35"}
const _background = "rgb(var(--ui-background) / <alpha-value>)"
const _foreground = "rgb(var(--ui-foreground) / <alpha-value>)"
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "overlay": _overlay, "testimonial": _testimonial, "snow": _snow, "blue": _blue, "red": _red, "green": _green, "pink": _pink, "yellow": _yellow, "text": _text, "background": _background, "foreground": _foreground, "primary": _primary, "gray": _gray, "green-dark": {"50":"#f3fafa","100":"#d6f1ef","200":"#ade2e0","300":"#7bcdcd","400":"#50aeb1","500":"#33888c","600":"#297378","700":"#255b60","800":"#214a4e","900":"#1f4042","950":"#0d2326"},  }
export { config as default, _transparent, _current, _black, _white, _overlay, _testimonial, _snow, _blue, _red, _green, _pink, _yellow, _text, _background, _foreground, _primary, _gray }