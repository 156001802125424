import type { Language, PageData, PageKey } from "~/types/page";

/**
 * **IMPORTANT: If you update the paths or slugs of this pageData, make sure to update them inside GLF Page Data plugin in the WP CMS, too.**
 */
const pageData: PageData = {
  "index": {
    en: "/",
    tr: "/",
    backendSlug: {
      en: "home",
      tr: "anasayfa",
    },
  },
  "about": {
    en: "/about",
    tr: "/hakkimizda",
    backendSlug: {
      en: "about",
      tr: "hakkimizda",
    },
  },
  "visas/index": {
    en: "/visas",
    tr: "/vizeler",
    backendSlug: {
      en: "visas",
      tr: "vizeler",
    },
  },
  "visas/[slug]": {
    en: "/visas/[slug]",
    tr: "/vizeler/[slug]",
  },
  "green-card/index": {
    en: "/green-card",
    tr: "/green-card",
    backendSlug: {
      en: "green-cards",
      tr: "green-card",
    },
  },
  "green-card/[slug]": {
    en: "/green-card/[slug]",
    tr: "/green-card/[slug]",
  },
  "blog/index": {
    en: "/blog",
    tr: "/blog",
    backendSlug: {
      en: "blog",
      tr: "blog",
    },
  },
  "blog/[blogSlug]": {
    en: "/blog/[blogSlug]",
    tr: "/blog/[blogSlug]",
  },
  "blog/category/[blogCategory]": {
    en: "/blog/category/[blogCategory]",
    tr: "/blog/kategori/[blogCategory]",
  },
  "success-story/index": {
    en: "/success-story",
    tr: "/basari-hikayesi",
    backendSlug: {
      en: "success-story",
      tr: "basari-hikayesi",
    },
  },
  "success-story/[slug]": {
    en: "/success-story/[slug]",
    tr: "/basari-hikayesi/[slug]",
  },
  "testimonial": {
    en: "/testimonial",
    tr: "/muvekkillerimizden",
    backendSlug: {
      en: "testimonial",
      tr: "muvekkillerimizden",
    },
  },
  "e-book/index": {
    en: "/e-book",
    tr: "/e-kitap",
    backendSlug: {
      en: "e-book",
      tr: "e-kitap",
    },
  },
  "e-book/[slug]": {
    en: "/e-book/[slug]",
    tr: "/e-kitap/[slug]",
  },
  "video": {
    en: "/video",
    tr: "/video",
    backendSlug: {
      en: "video",
      tr: "video",
    },
  },
  "webinar": {
    en: "/webinar",
    tr: "/webinar",
    backendSlug: {
      en: "webinar",
      tr: "webinar",
    },
  },
  "contact": {
    en: "/contact",
    tr: "/bize-ulasin",
    backendSlug: {
      en: "contact",
      tr: "bize-ulasin",
    },
  },
  "search": {
    en: "/search",
    tr: "/ara",
  },
  "privacy-policy": {
    en: "/privacy-policy",
    tr: "/gizlilik-politikasi",
    backendSlug: {
      en: "privacy-policy",
      tr: "gizlilik-politikasi",
    },
  },
  "terms-of-use": {
    en: "/terms-of-use",
    tr: "/kullanim-kosullari",
    backendSlug: {
      en: "terms-of-use",
      tr: "kullanim-kosullari",
    },
  },
};

export function usePath() {
  const localePath = useLocalePath();

  /**
   * We should always use this instead of using useLocalePath directly.
   * This is because localePath requires pages with slugs to be inputted as 'page' but
   * it also requires them to inputted as 'page/index' in nuxt.config. So we use this
   * to make them coherent and type-safe.
   */
  function getLocalePath(key: PageKey) {
    const fixedKey = key.replace(/\/index$/, "");
    return localePath(fixedKey);
  }

  function getFrontendSlug(key: PageKey, language: Language): string {
    return pageData[key][language];
  }

  function getBackendSlug(key: PageKey, language: Language): string {
    const page = pageData[key];
    if ("backendSlug" in page && typeof page.backendSlug === "object") {
      return page.backendSlug[language];
    }
    return "";
  }

  return {
    getLocalePath,
    getFrontendSlug,
    getBackendSlug,
  };
}
